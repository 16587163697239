import React from 'react'
import { graphql } from 'gatsby'
import { CarouselProvider, Slide, Dot } from 'pure-react-carousel'

import { Carousel, Fade, Layout, Preview, Previews, Swipe } from '../components'
import {pickQuality} from "../../utils/pick-quality";

class Index extends React.Component {

  constructor(props) {
    super(props)

    this.dots = React.createRef()
    this.carousel = React.createRef()

    this.players = []

    this.onChangeCurrentSlide = this.onChangeCurrentSlide.bind(this)
    this.onSwipe = this.onSwipe.bind(this)
    this.onPreviewReady = this.onPreviewReady.bind(this)

    this.state = {
      currentSlide: -1,
      readyInterval: 8000,
      interval: 20000 // bigger than normal
    }

    this.carouselSettings = {
      isPlaying: true,
      touchEnabled: false,
      dragEnabled: false,
      naturalSlideWidth: 100,
      naturalSlideHeight: 0,
      totalSlides: this.props.data.datoCmsHome.slider.length
    }
  }

  componentDidMount() {
    this.onChangeCurrentSlide(0)
  }

  onChangeCurrentSlide(slide) {
    this.setState({currentSlide: slide})
    // hack to reset interval on user interaction
    this.carousel.current.instance.stop()
    this.carousel.current.instance.play()

    this.players.forEach(player => player.pause())
    this.players[slide].play()
  }

  onClickControl(direction) {
    this.carousel.current.instance[direction]()
  }

  onSwipe(direction) {
    const controls = {left: 'prev', right: 'next'}
    this.carousel.current.instance[controls[direction]]()
  }

  renderSpan(key, node) {
    if (node[key]) {
      return (
        <React.Fragment>
          {node[key]}
          <br/>
        </React.Fragment>
      )
    } else {
      return ''
    }
  }

  playerRefs = (id) => (ref) => {
    this.players[id] = ref
  }

  onPreviewReady = (i) => () => {
    if (i === 0) {
      this.setState({
        interval: this.state.readyInterval
      }, () => {
        this.carousel.current.instance.stop()
        this.carousel.current.instance.play()
      })
    }
  }

  shouldComponentUpdate() {
    return true
  }

  render() {
    this.carouselSettings.interval = this.state.interval
    return (
      <Layout page={this}>
        <div className="index">
          <div className="fixed h-full w-full overflow-auto">

            <Swipe onSwipe={this.onSwipe}>
              <CarouselProvider {...this.carouselSettings} className="index__carousel">
                <Carousel className="index__slider" classNameAnimation="fade" classNameTray="index__tray" onChangeCurrentSlide={this.onChangeCurrentSlide} ref={this.carousel}>
                  {
                    this.props.data.datoCmsHome.slider.map((node, i) => (
                      <Slide index={i} key={node.id} className={`index__slide ${i < this.state.currentSlide ? 'is-before' : (i > this.state.currentSlide) ? 'is-after' : ''}`} classNameHidden="index__slide--hidden" classNameVisible="index__slide--visible">
                        <Fade to={`${node.link}`} className="index__slide-inner refresh">
                          <Previews>
                            <Preview className="h-full w-full" blur={`${node.cover.url}?auto=format&fit=min&w=100&blur=100`} cover={`${node.cover.url}?auto=format&fit=min&w=1200`} src={pickQuality(node)} noPause autoPlay={(i===0)} ref={this.playerRefs(i)} onReady={this.onPreviewReady(i).bind(this)} preload={(i===0) ? 'auto' : 'metadata'} mobileVideo>
                              <div>
                                <span className="font-head text-20 md:text-22 uppercase tracking-wider leading-tight">{this.renderSpan('head', node)}</span>
                                <h2 className="font-head text-36 md:text-38 uppercase tracking-wide leading-tight">{node.title}</h2>
                                <span className="font-main text-16 uppercase leading-tight">{this.renderSpan('subtitle', node)}</span>
                              </div>
                            </Preview>
                          </Previews>
                        </Fade>
                      </Slide>
                    ))
                  }
                </Carousel>
                <div className="index__controls hidden md:block">
                  <div className="wrapper">
                    <div className="relative">
                      <div className="index__control index__control--back" onClick={() => { this.onClickControl('prev') }}></div>
                      <div className="index__control index__control--next" onClick={() => { this.onClickControl('next') }}></div>
                    </div>
                  </div>
                </div>
                <div className="index__dots">
                  <div className="wrapper flex" ref={this.dots}>
                    {
                      this.props.data.datoCmsHome.slider.map((node, i) => (
                        <Dot slide={i} className={`index__dot ${(i === this.state.currentSlide) ? 'is-active' : 'no-transition'}`} key={i}>
                          <span style={{transitionDuration: `${this.state.readyInterval}ms`}}></span>
                        </Dot>
                      ))
                    }
                  </div>
                </div>
              </CarouselProvider>
            </Swipe>

          </div>
        </div>
      </Layout>
    )
  }
}

export default Index

export const query = graphql`
  query HomeQuery {
    datoCmsHome {
      slider {
        id
        head
        title
        subtitle
        mp4Url
        mp4UrlSd
        cover {
          url
        }
        link
      }
    }
  }
`
